import { createApp } from 'vue'
import App from './App.vue'
import './main.css'
import 'element-plus/dist/index.css'
import ElementPlus from 'element-plus'
import { createRouter, createWebHashHistory } from 'vue-router';
import QuestionPanel from './view/QuestionPanel.vue'
import QuestionPanelV2 from './view/QuestionPanelVersion2.vue'
import PageNotFound from "./view/PageNotFound.vue"

import * as Sentry from "@sentry/vue";


const app = createApp(App)
const router = createRouter({
    history: createWebHashHistory(),
    routes: [
        //for testing
        // { path: '/staging/nutripro-followup/:barcode', component: QuestionPanelV2, props: { surveyType: "nutripro-followup" } },
        //for nutripro
        { path: '/nutripro/:barcode', component: QuestionPanelV2, props: { surveyType: "nutripro" }, alias: ['/:barcode', '/survey/:barcode'] },
        //for nutripro-followup
        { path: '/nutripro-followup/:barcode', component: QuestionPanelV2, props: { surveyType: "nutripro-followup" } },
        //for skincare
        { path: '/skincare/:barcode', component: QuestionPanelV2, props: { surveyType: "skincare" } },
        //for skincare followup question
        { path: '/skincare-followup/:barcode', component: QuestionPanelV2, props: { surveyType: "skincare-followup" } },
        //for others
        { path: '/:catchAll(.*)', component: PageNotFound }
    ]
})

Sentry.init({
    app,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
        Sentry.browserTracingIntegration({ router }),
    ],
    allowUrls: [
       "www.vibrant-america.com/report-questionnaire/",
    ],
    tracesSampleRate: 1.0,
});

app.use(router)
app.use(ElementPlus)
app.mount('#app')